html {
  font-size: 1vw;
}

body {
  margin: 0;
  font-family: Montserrat, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-style: italic; */
}

.setPosition {
  top: 2rem;
  position: absolute;
  left: 3rem;
}

.fontSemiBold {
  font-weight: 600;
}

#filterModal {
  transition: none;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' ><filter id='svgMask'><feGaussianBlur stdDeviation='5' /></filter></svg>#svgMask");
}

.ant-menu:not(.ant-menu-horizontal) .landingNav.ant-menu-item-selected {
  background: transparent;
}

.landingNav.ant-menu-item>a:hover,
.landingNav.ant-menu-item>a:active,
.landingNav.ant-menu-item:hover,
.landingNav.ant-menu-item-active,
.landingNav.ant-menu-item-selected,
.landingNav.ant-menu-item-selected>a,
.landingNav.ant-menu-item-selected>a:hover {
  color: #58ba27;
}

.ant-message {
  top: 5rem !important;
  z-index: 3001;
}

.imagePreviewModal .ant-modal-close-x {
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 28px;
}

@media screen and (max-width: 1199px) {
  html {
    font-size: 1.15vw;
  }
}

@media screen and (max-width: 991px) {
  html {
    font-size: 16px;
  }
}

@media screen and (max-width: 576px) {
  html {
    font-size: 13px;
  }
}